.footer {
    padding: 24px;
    text-align: center;
    margin: 0 auto;
    background-color: black;
    font-family: 'Montserrat';
    font-size: 9px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: center;
    padding-bottom: 78px;
}

.contacts-main-page {
    background: black;
    text-align: left;
}
.contacts-title {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;
    margin-top: 5px;
}

.contacts-block {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 29px;
    align-items: center;
}

.contacts-text {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

.button-contact-us {
    width: 156px;
    background: transparent;
    color: white;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    padding: 10px 0;
    background-image: linear-gradient(90deg, black, black),
    linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 1px solid transparent;
    border-radius: 100px;
    margin-left: 16px;
}

.whats-app-button {
    background: linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
    box-shadow: inset -1px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    border: unset;
    padding: 0 24px;
}

.text-center {
    text-align: center;
    justify-content: center;
}

@media screen and (min-width: 991px) {
    .nav-footer-text {
        padding-top: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-decoration: none;
        color: white;
        margin-right: 28px;
    }
    .footer-text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        padding: 30px 0 35px;
        margin: 0;
    }
}
