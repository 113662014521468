.master-title {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 5px;
    text-align: center;

}

.masters-page {
    background: black;
    padding: 0 24px 24px;
}

.masters-block-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.master-card {
    width: calc(50% - 8px);
    max-width: 180px;
}

.card-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px 0;
    max-width: 180px;
}

.master-description {
    display: flex;
    font-family: 'Montserrat';
    font-size: 11px;
    font-weight: 400;
    line-height: 11px;
    align-content: center;
}

.master-description-text {
    font-family: 'Montserrat-bold';
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    text-align: center;
    max-width: 317px;
    margin: 28px auto 20px;
}

.description-two {
    max-width: 317px;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    margin: 0 auto 20px;
}

.desc-icon {
    width: 13px;
    height: 13px;
    margin-left: 5px;
}
.button-all-masters {
    width: 100%;
    background: transparent;
    color: white;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    padding: 10px 0;
    background-image: linear-gradient(90deg, black, black),
    linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 1px solid transparent;
    border-radius: 100px;
    margin-top: 20px;
}

.master-id-title {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 5px;
    text-align: left;
}

.desc-icon {
    margin-right: 1px;
}

.master-id-info {
    display: flex;
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    align-content: center;
    margin-bottom: 16px;
}

.master-images-row {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-bottom: 40px;
}

.master-id-desc-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.logo-img-pop-up {
    text-align: center;
}

.pop-up-title{
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 40px;
    margin-bottom: 20px;
}

.pop-up-description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.input-pop-up{
    background: transparent;
    color: #FFFFFF;
    background-image: linear-gradient(90deg, black, black),
    linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 1px solid transparent;
    border-radius: 100px;
    min-height: 28px;
    /*min-width: 302px;*/
    width: 100%;
    margin-bottom: 20px;
}

.input-pop-up::placeholder {
    color: grey;
}

@media screen and (min-width: 648px) and (max-width: 990px) {
    .masters-block-page {
        justify-content: start;
    }
    .master-card {
        margin-right: 20px;
    }
}

@media screen and (min-width: 991px) {
    .master-title {
        font-size: 50px;
        line-height: 61px;
        margin-top: 60px;
        margin-bottom: 40px;
    }
    .master-card {
        width: 350px;
        max-width: unset;
        margin-right: 40px;
    }
    .masters-block-page {
        justify-content: center;
        max-width: 1500px;
        margin: 0 auto;
    }
    .master-description {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        font-variant: small-caps;
    }
    .desc-icon {
        width: 25px;
        height: 25px;
    }
    .card-info {
        width: 350px;
        max-width: unset;
        margin: 20px 0;
    }
    .description-two {
        font-size: 18px;
        line-height: 22px;
        max-width: 808px;
        margin-bottom: 67px;
    }
    .master-id-title {
        font-family: 'Montserrat-Bold';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 49px;
        font-variant: small-caps;
    }
}