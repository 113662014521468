.main-bg-image {
    position: relative;
    top: 0;
    background-image: url("/src/utils/images/homePage/main-bg-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    width: 100vw;
    height: 80vh;
    z-index: 1;
    margin-bottom: 30px;
}

@media screen and (min-width: 600px) {
    .main-bg-image {
        background-image: url("/src/utils/images/homePage/main-bg-mobile-width.png");
        background-size: 100vw 100%;
        width: 100vw;
        margin-bottom: 0;
        height: auto;
        min-height: 350px;
    }
    .banner-mobile {
        width: 60%;
        border-bottom-right-radius: 150px;
        border-top-right-radius: 150px;
    }
}

.main-page {
    position: relative;
    padding: 32px 24px 0;
    text-align: left;
    z-index: 2;
}
.logo {
    text-align: left;
}

.main-title {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    color: #FFFFFF;
    margin-bottom: 32px;
    margin-top: 16px;
    max-width: 327px;
}

.button-choose {
    float: right;
    display: flex;
    background: linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
    border-radius: 50%;
    width: 140px;
    height: 140px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12.2286px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.banner-text {
    background:linear-gradient(132deg, #B469FF 26.49%, #DD3A99 100%);
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    text-align: left;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-bottom: 24px;
    padding-top: 24px;
    padding-left: calc(100vw * 0.125);
    border-bottom-right-radius: 150px;
    border-top-right-radius: 150px;
    width: 734px;
    position: absolute;
    bottom: -14px;
    left: calc(100vw * -0.125);
}

.banner-mobile {
    background:linear-gradient(132deg, #B469FF 26.49%, #DD3A99 100%);
    font-family: 'Montserrat-Bold';
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    text-align: left;
    line-height: 130.9%;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 18px 24px;
}

@media screen and (min-width: 991px) {
    .main-bg-image {
        position: relative;
        background-image: url("/src/utils/images/homePage/main-bg.png");
        background-size: 100vw 100vh;
        width: 100vw;
        height: 67vw;
        max-height: 100vh;
    }
    .main-page {
        margin: calc(100vh * 0.14) calc(100vw * 0.125) 0;
        padding: 0;
    }
    .main-title {
        font-size: 50px;
        line-height: 61px;
        max-width: 492px;
        margin-bottom: 16px;
    }
    .main-description {
        display: flex;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 34px;
        max-width: 503px;
    }
    .button-choose {
        cursor: pointer;
    }
    .waiting-you-title {
        font-family: 'Montserrat-Bold';
        font-style: normal;
        font-weight: 800;
        font-size: 50px;
        line-height: 61px;
        margin: 40px 0 30px;
    }
    .waiting-you-description {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin: 0 auto 20px;
        max-width: 565px;
        text-align: center;
    }
    .input-field{
        background: transparent;
        color: #FFFFFF;
        border: 1px solid transparent;
        border-radius: 100px;
        min-height: 28px;
        width: 714px;
    }

    .input-field::placeholder {
        color: grey;
    }

    .input-field:hover {
        border: 1px solid black;
    }

    .button-main-page-send {
        width: 155px;
        background: transparent;
        color: white;
        font-family: 'Montserrat';
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        text-align: center;
        padding: 10px 0;
        background-image: linear-gradient(90deg, black, black),
        linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
        background-clip: padding-box, border-box;
        background-origin: border-box;
        border: 1px solid transparent;
        border-radius: 100px;
        margin-top: 20px;
    }

    .nav-header-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-decoration: none;
        color: white;
        margin-right: 28px;
    }

    .button-main-page-call {
        width: 155px;
        background: transparent;
        color: white;
        font-family: 'Montserrat';
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        text-align: center;
        padding: 8px 0;
        background-image: linear-gradient(90deg, black, black),
        linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
        background-clip: padding-box, border-box;
        background-origin: border-box;
        border: 1px solid transparent;
        border-radius: 100px;
    }
}