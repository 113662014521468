.stock-title {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 5px;
}

.main-stocks-page {
   background: black;
   padding: 24px 24px 56px;
   text-align: left;
}

.stock-card {
    width: calc(33% - 8px);
}

.card-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.stock-description {
    display: flex;
    font-family: 'Montserrat-Bold';
    font-size: 12px;
    font-weight: 800;
    line-height: 15px;
    text-align: left;
    white-space: pre-line;
    max-width: 123px;
}
.button-more-stocks {
    width: 100%;
    background: transparent;
    color: white;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    padding: 10px 0;
    background-image: linear-gradient(90deg, black, black),
    linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 1px solid transparent;
    border-radius: 100px;
    margin-top: 20px;
}

@media screen and (min-width: 991px) {
    .main-stocks-page {
        position: relative;
    }
    .stock-title {
        font-size: 50px !important;
        line-height: 61px !important;
        text-align: center;
    }
    .stock-card-title {
        font-family: 'Montserrat-Bold';
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 39px;
        max-width: 352px;
        white-space: pre-line;
        margin: 0;
    }
    .stock-card-description {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        max-width: 424px;
        margin: 20px 0 40px;
    }
    .button-more-stocks {
        max-width: 194px;
        cursor: pointer;
    }
    .arrow-left-circle {
        position: absolute;
        top: 470px;
        left: calc(10vw)
    }
    .arrow-right-circle {
        position: absolute;
        top: 470px;
        right: calc(10vw)
    }
}