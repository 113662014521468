.master-title {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 5px;
}

.master-title-main-page {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 5px;
    text-align: left;
}

.our-masters-page {
    background: black;
    padding: 50px 24px 24px;
}

.masters-main-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.master-card {
    width: calc(50% - 8px);
    max-width: 180px;
}

.card-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.master-description {
    display: flex;
    font-family: 'Montserrat';
    font-size: 11px;
    font-weight: 400;
    line-height: 11px;
    align-content: center;
}

.desc-icon {
    width: 13px;
    height: 13px;
    margin-left: 5px;
}
.button-all-masters {
    width: 100%;
    background: transparent;
    color: white;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    padding: 10px 0;
    background-image: linear-gradient(90deg, black, black),
    linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 1px solid transparent;
    border-radius: 100px;
    margin-top: 20px;
}

@media screen and (min-width: 601px) and (max-width: 990px) {
    .master-title-main-page {
        margin-top: 35px;
    }
}

@media screen and (min-width: 991px) {
    .master-title-main-page {
        font-size: 50px;
        line-height: 61px;
        text-align: center;
        margin-top: 37px;
        margin-bottom: 77px;
    }
    .masters-main-page {
        justify-content: center;
    }
    .button-all-masters {

    }
}