.stock-title {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 5px;
}

.stock-page {
    background: black;
    padding: 0 24px 24px;
}

.stocks-card {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 30px;
}

.card-text {
    width: calc(100% - 181px);
    margin-left: 20px;
    white-space: pre-line;
    max-width: 200px;
}

.card-title {
    font-family: 'Montserrat-Bold';
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    text-align: left;
    margin: 0 0 20px;
}

.card-description {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0;
    text-align: left;
    margin: 0 0 20px;
}

.button-bron {
    width: 100%;
    background: transparent;
    color: white;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    padding: 5px 0;
    background-image: linear-gradient(90deg, black, black),
    linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 1px solid transparent;
    border-radius: 100px;
}