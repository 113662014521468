.benefits-page {
    background: black;
    padding: 24px;
    text-align: left;
    background-image: url("/src/utils/images/benefits/background.png");
    background-repeat: no-repeat;
    background-size: 343px 483px;
    background-position: bottom right;
}
.benefits-title {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 5px;
}

.benefits-block {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 29px;
}

.benefits-text {
    width: calc(100% - 58px);
    max-width: 303px;
    font-family: 'Montserrat';
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
    white-space: pre-line;
}

@media screen and (min-width: 991px) {
    .benefits-page {
        background-image: unset;
        min-height: 890px;
    }
    .benefits-title {
        font-size: 50px;
        line-height: 61px;
    }
    .benefits-text {
        margin-left: 25px;
        width: unset;
        max-width: 436px;
        font-size: 16px;
        line-height: 20px;
    }
}