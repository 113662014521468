@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('Montserrat-Regular.woff2') format('woff2'),
        url('Montserrat-Regular.woff') format('woff'),
        url('Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

@font-face {
    font-family: 'Montserrat-Bold';
    src: local('Montserrat Extra Bold'), local('Montserrat-Extra-Bold'),
    url('Montserrat-ExtraBold.woff2') format('woff2'),
    url('Montserrat-ExtraBold.woff') format('woff'),
    url('Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}