.interior-title {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 5px;
}

.interior-page {
    background: black;
    padding: 0 24px 24px;
}

.interior-description {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    max-width: 364px;
    margin: 0 auto;
}

.button-bron {
    width: 100%;
    background: transparent;
    color: white;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    padding: 5px 0;
    background-image: linear-gradient(90deg, black, black),
    linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 1px solid transparent;
    border-radius: 100px;
}