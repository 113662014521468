.App {
  text-align: center;
  color: #FFFFFF;
  background: black;
}

.text-white {
  color: #FFFFFF;
}

.font-montserrat {
  font-family: 'Montserrat';
}

.pink-gradient-button {
  background: linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
  box-shadow: inset -1px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  border: unset;
  padding: 0 24px;
  min-width: 155px;
}

.text-12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}

.text-14 {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.ant-modal-content {
  background-color: black !important;
}

.logo-img {
  width: 100%;
  padding-top: 20vh;
  cursor: pointer;
}

.adult-description {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.adult-bold {
  font-family: Montserrat-Bold;
}