.mobile-bar {
    color: white;
    background: rgba(74, 74, 74, 1);
    display: flex;
    flex-wrap: wrap;
    height: 54px;
    position: fixed;
    bottom: 0;
    width: 100vw;
    z-index: 1000;
}

.tab-bar {
    display: block;
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    width: 33%;
    border-right: 1px solid black;
    padding-top: 8px;
}