.our-programms-page{
    padding-top: 20px;
    background-color: black;
    color: white;
    text-align: left;
    padding-bottom: 16px;
}

.carousel-block {
    width: calc(100% - 20px) !important;
}

.card-block {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.programms-main-page{
    padding-left: 24px;
}

.our-programms-title {
    padding-left: 24px;
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
}
.programm-card-title {
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    font-weight: 800;
    line-height: 17px;
    text-align: left;
    color: white;
    position: absolute;
    top: 10px;
    left: 7px;
    max-width: 140px;
}

.program-info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10.8px;
    line-height: 13px;
    color: #FFFFFF;
    position: absolute;
    bottom: 16px;
    left: 7px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.program-icons {
    margin-right: 3px;
}

@media screen and (min-width: 991px) {
    .our-programms-page{
        padding-top: 40px;
        background-color: black;
        color: white;
        text-align: left;
        padding-bottom: 80px;
    }
    .our-programms-title {
        font-size: 50px;
        line-height: 61px;
        text-align: center;
        margin-top: 37px;
        margin-bottom: 77px;
    }
    .programm-card-title {
        font-family: 'Montserrat-Bold';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        max-width: 196px;
    }
    .programms-main-page {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .card-block {
        width: 360px;
        height: 303px;
        margin-right: 40px;
    }
    .programm-card-title {
        top: 32px;
        left: 24px;
    }
    .program-info {
        font-size: 12px;
        line-height: 15px;
        bottom: 32px;
        left: 24px;
    }
}