.interior-main-page {
    padding: 24px 24px 56px;
    background: black;
}

.interior-main-title {
    text-align: left;
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
}

.interior-block {
    position: relative;
}

.left-arrow {
    position: absolute;
    left: 10px;
    top: 109px;
}
.right-arrow {
    position: absolute;
    right: 10px;
    top: 109px;
}

.row-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (min-width: 991px) {
    .interior-main-page {
        padding-bottom: 30px;
    }
    .interior-main-title {
        font-size: 50px;
        line-height: 61px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 40px;
    }
    .interior-desc{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 60px;
    }
    .interior-block {
        display: flex;
        justify-content: center;
    }
}