@media screen and (min-width: 991px) {
    .main-contacts-page {
        background: #140101;
    }
    .main-contacts-title {
        padding-top: 66px;
        font-family: 'Montserrat-Bold';
        font-style: normal;
        font-weight: 800;
        font-size: 50px;
        line-height: 61px;
        margin-top: 0;
    }
    .main-contacts-text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        align-items: center;
    }
}