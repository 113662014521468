.stock-title {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 5px;
}

.stock-page {
    background: black;
    padding: 0 24px 24px;
}

.programms-card {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 16px;
}

.programm-title {
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    font-weight: 800;
    line-height: 17px;
    text-align: left;
    color: white;
    position: absolute;
    top: 10px;
    left: 16px;
    max-width: 295px;
}

.programm-card-info{
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    text-align: left;
    color: #FFFFFF;
    position: absolute;
    bottom: 16px;
    left: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.programm-card-info-add {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    text-align: left;
    color: #FFFFFF;
    position: absolute;
    bottom: 36px;
    left: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.card-text {
    width: calc(100% - 181px);
    margin-left: 20px;
    white-space: pre-line;
    max-width: 200px;
}

.card-title {
    font-family: 'Montserrat-Bold';
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    text-align: left;
    margin: 0 0 20px;
}

.card-description {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0;
    text-align: left;
    margin: 0 0 20px;
}

.card-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
}

.button-learn-more {
    width: calc(50% - 10px);
    background: transparent;
    color: white;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    padding: 10px 0;
    background-image: linear-gradient(90deg, black, black),
    linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 1px solid transparent;
    border-radius: 100px;
}

.card-description-block {
    text-align: left;
}

.programm-includes {
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    font-weight: 700;
    line-height: 29px;
    text-align: left;
    margin: 16px 0;
}

.description-list {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}

.classic-info {
    display: flex;
    flex-wrap: wrap;
    align-items: normal;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    text-align: left;
    margin-bottom: 16px;
}

ul {
    list-style-type: none;
    padding-left: 10px;
}
li::before {
    background: linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
    border-radius: 50%;
    content: "";
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 2px;
    height: 5px;
    width: 5px;
}

@media screen and (min-width: 600px) {
    .description-list {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
    }
}

@media screen and (min-width: 991px) {
    .description-list {
        width: 360px;
    }
    .stock-title {
        margin: 60px 0;
    }
    .ant-modal-content {
        padding: 0 !important;
    }
}