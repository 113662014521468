.header-button {
    display: flex;
    align-items: center;
    background: linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%);
    box-shadow: inset -1px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    border: unset;
    width: 156px;
    height: 32px;
    padding: 9px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
}

.header-mobile-menu {
    background: url('/src/utils/images/header/header-menu-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100vw;
    height: 100vh;
}

.whats-app-btn {
    width: 156px;
    background: transparent;
    color: white;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    padding: 10px 0;
    border: 0.5px solid rgba(255, 81, 233, 0.8);
    border-radius: 100px;
    margin-top: 20px;
}

.header-menu-block {
    margin-top: 88px;
    font-family: 'Montserrat-Bold';
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
}

.nav-text {
    text-decoration: none;
    color: white;
}

.nav-title {
    font-family: 'Montserrat-Bold';
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    text-align: center;
    margin: 24px 0;

}

@media screen and (min-width: 600px) and (max-width: 990px){
    .header-mobile-menu {
        background: url('/src/utils/images/header/header-menu-bg-width.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100vw;
        height: 100vh;
        min-height: 600px;
    }
}
@media screen and (max-width: 991px) {
    .header {
        padding: 24px;
    }
}
